.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#root {
  height: 100%;
  width: 100%;
}

* { /* Derek's reset*/
	background: transparent;
	border: 0;
	font-size: 100%;
	font-weight: 600;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}
body, html {
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	text-size-adjust: none;
	-webkit-text-size-adjust: none;
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
}

h1 {
  font-size: 2.6em;
}

.front-photoshart-main {
  background: linear-gradient(270deg, #caa957 0%, #9a771f 100%);
  background: -moz-linear-gradient(270deg, #caa957 0%, #9a771f 100%);
  background: -webkit-linear-gradient(270deg, #caa957 0%, #9a771f 100%);
  background: -o-linear-gradient(270deg, #caa957 0%, #9a771f 100%);
  background-size: 400% 400%;
  -webkit-animation: Sharterific 65s ease infinite;
  -moz-animation: Sharterific 65s ease infinite;
  -o-animation: Sharterific 65s ease infinite;
  animation: Sharterific 65s ease infinite;
  height: 100%;
  width: 100%;
  text-align: center;
}

.front-photoshart-main-h1 {
  position: relative;
  padding: 320px 0px 0px 0px;
}

@-webkit-keyframes Sharterific {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes Sharterific {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-o-keyframes Sharterific {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes Sharterific {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

/* Images and their effects go here... */

.img_poo_click {
  height: 120px;
  width: auto;
  cursor: pointer;
  transition: ease all 0.8s;
}

.img_poo_click:active {
  height: 20px;
}